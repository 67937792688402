.login {
  .logo {
    margin: 30px 0 10px 0;
  }
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  max-width: fit-content;

  .divider {
    width: 100%;
    
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 15px;
    
    p {
      user-select: none;
      color: darken($background-color, 20%)
    }

    .line {
      height: 3px;
      background-color: rgb(240, 240, 240);
      width: 100%;
      border-radius: 100px;
    }
    
  }

  .button {
    user-select: none;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .oauth2-options {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
}



