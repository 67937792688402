.logo {
  display: grid;
  grid-template-columns: 1fr auto 1fr;

  align-items: center;
  gap: 10px;
  font-weight: bold;
  font-size: 30px;

  user-select: none;

  * {
    font-family: "Jost", sans-serif;
    text-transform: uppercase;
    color: $header-color;
  }
}