.view {

  height: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;


  &.center {
    justify-content: center;
    align-items: center;
  }
}

.main {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: max-content auto;
  gap: 20px;
  align-items: start;
  
  & > *:first-child {
    grid-column: 1 / 3;
  }
}

.view-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
