.profile-editor {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .profile-editor__nav {
    padding: 10px;
    position: sticky;
    top: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }
  
  .profile-editor__actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 20px 0;
  }

  .link-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .profile-editor__profile {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}


