@import "base/variables";
@import "base/global";
@import "base/elements";
@import "base/typography";

@import "components/button";
@import "components/container";
@import "components/login";
@import "components/logo";
@import "components/platform-picker";
@import "components/link-button";
@import "components/profile";
@import "components/editor";
@import "components/label-input";
@import "components/file-input";

@import "layout/view";

.shadow {
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  border: 1px solid rgba(0, 0, 0, 0.025)
}

.link-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: $background-color;
  padding: 20px;
  border-radius: $border-radius;
  border: 1px solid rgba(0, 0, 0, 0.025);

  .link-form__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/* 
body {
  background-image: url("../../public/bg3.svg");
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: center;
  background-size: contain;
  transform: skewX(50deg);
}
 */