
.platform-picker {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: $border-radius;
  border: 1px solid rgba(0, 0, 0, 0.075);
  height: 50px;

  .platform-picker__open-button {
    width: inherit;
    height: inherit;
  }
}

.platform-picker__dropdown {
  z-index: 5;
  
  width: 100%;
  background-color: $element-color;
  display: grid;
  grid-template-rows: auto 1fr;
  border-radius: $border-radius;
  overflow: hidden;

  position: absolute;
  top: 0;

  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;

  .divider {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .platform-picker__format {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;

    input {
      padding: 0 0 0 10px;
      flex-grow: 2;

      border: none;
      background-color: $background-color;
      border-radius: $border-radius;
      font-size: 16px;
    }
  }
  
  .platform-picker__options {
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;

    &.grid {
      display: grid;
      gap: 5px;
      grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));

      * {
        aspect-ratio: 1;
      }
    }

    &.list {
      display: flex;
      flex-direction: column;
      gap: 1px;

      button {
        gap: 16px;
      }
    }
  }
}