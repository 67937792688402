.profile {
  & .skeleton {
    background-color: rgba(0, 0, 0, 0.075);
    border-radius: $border-radius;
    display: block;
  }

  display: flex;
  flex-direction: column;
  gap: 10px;

  max-width: 500px;

  .profile__user-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
    
    margin: 20px 0;

    .user-picture {
      height: 120px;
      width: 120px;
      border-radius: 100%;
      object-fit: cover;
    }

    .user-name {
      &.skeleton {
        height: 32px;
        width: 180px;
      }
    }

    .profile__bio {
      &.skeleton {
        height: 100px;
        width: 100%;
      }
    }
  }

  .profile__links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    border-radius: $border-radius;
  }
}