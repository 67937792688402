* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.5;
  min-width: 0;
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #dcdcdc #ffffff;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}


h1, h2, h3, h4 {
  color: $header-color;
}

p, span, button, input, label, textarea {
  color: $text-color;
}

html, body, #root, .App {
  height: 100%;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
}

body {
  padding: 20px;
  background-color: #FFFFFF;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='724' height='86.9' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23C5C9DF' stroke-width='9.1' stroke-opacity='0.46'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
}

/* body {
  padding: 20px;
  background-image: url(../../../public/bg3.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
} */

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

textarea {
  border-radius: $border-radius;
  border: 1px solid rgba(0, 0, 0, 0.075);
  padding: 10px;
  resize: none;
  height: 250px;
}